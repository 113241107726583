import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/Users/ichwan/Documents/repos/new-personal-site/node_modules/@pauliescanlon/gatsby-theme-terminal/src/layouts/PageLayout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Text = makeShortcode("Text");
const Divider = makeShortcode("Divider");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Resume`}</h1>
    <p>{`You can find my current resume by clicking on this link: `}<a parentName="p" {...{
        "href": "https://drive.google.com/file/d/1p_R5i9uuNm3-xn0HSTEDUikqL_p4d-EL/view?usp=sharing"
      }}>{`Download Resume`}</a></p>
    <h1>{`Experience`}</h1>
    <Text as='h2' id="ax" mdxType="Text">Software Test Engineer at Axoni</Text>
    <Text mdxType="Text">Apr 2019 - Present</Text>
    <Text as='li' variant='ul' mdxType="Text">Decreased the time taken to run regression tests for AxCore, the core distributed ledger software, by porting disparate test scripts
from Bash to Python and consolidating them under one test harness using PyTest library in Python</Text>
    <Text as='li' variant='ul' mdxType="Text">Created the user interface test suite for the equity swap platform project using Cypress and Javascript and set up the CI/CD
pipeline to automatically trigger the running of the aforementioned test suite upon the release of a new UI build</Text>
    <Text as='li' variant='ul' mdxType="Text">Developed and currently maintains test suites for testing both the FIX server and UI portions of the bond issuance platform
using ZIO library/Scala and Cypress/Javascript, respectively</Text>
    <Text as='li' variant='ul' mdxType="Text">Create and implement new test cases for different projects using their respective syntax for frameworks and languages</Text>
    <Text as='li' variant='ul' mdxType="Text">Ensure compliance with the SOC2 procedure in the role as a delegate for the QA team</Text>
    <Divider mdxType="Divider" />
    <Text as='h2' id="fp" mdxType="Text">Software Engineering Intern at Flashpoint</Text>
    <Text mdxType="Text">Oct 2018 - Jan 2019</Text>
    <Text as='li' variant='ul' mdxType="Text">Expanded the breadth of data sources offered through by the platform by writing 10 new scrapers for new data sources including
the first data source for a new language using Python and BeautifulSoup</Text>
    <Text as='li' variant='ul' mdxType="Text">Developed new parser schemas to be used for the next generation data collection tools by leveraging XPath and Python and
maintained the throughput of the system by performing maintenance for modules that are throwing errors</Text>
    <Text as='li' variant='ul' mdxType="Text">Contributed to the writing of documentations and runbooks to enable for team to be force multipliers of each other</Text>
    <Divider mdxType="Divider" />
    <Text as="h2" id="ek" mdxType="Text">Software Developer Intern at Enerknol</Text>
    <Text mdxType="Text">Jun 2018 - Aug 2020</Text>
    <Text as='li' variant='ul' mdxType="Text">Maintained and monitored the existing web application by introducing bug fixes and developing new features using Flask, jQuery,
and Elasticsearch to enhance user experience and extend the functionalities of the platform</Text>
    <Text as='li' variant='ul' mdxType="Text">Created and maintained spider scripts to scrape various energy-related websites using Python and Scrapy framework</Text>
    <Divider mdxType="Divider" />
    <Text as='h2' mdxType="Text">Software Developer Intern at New York City Transit</Text>
    <Text mdxType="Text">Jul 2017 - Nov 2017</Text>
    <Text as='li' variant='ul' mdxType="Text">Developed an internal hybrid app using Angular and Ionic Framework that is going to be deployed in iOS devices 
    for the use of approximately 15 personnel within the Passenger Environmental Survey (PES) group. The data collected through the application 
    is then used to determine policy-making decisions with regards to subway availabilities, cleanliness and station renovation</Text>
    <Divider mdxType="Divider" />
    <Text as='h2' mdxType="Text">Software Developer Intern at New York City Transit</Text>
    <Text mdxType="Text">May 2016 - Jan 2017</Text>
    <Text as='li' variant='ul' mdxType="Text">Developed an internal Microsoft Access application using VBA and SQL to track the number of hours expended 
    by each individual members of the ~100 persons Engineering Services subdivision</Text>
    <Text as='li' variant='ul' mdxType="Text">Performed intense data entry to the database that power the back-end of the application being developed 
    with the most up-to-date data from other internal tracking systems</Text>
    <Divider mdxType="Divider" />
    <Text as='h2' mdxType="Text">Assistant System Administrator at City College of New York</Text>
    <Text mdxType="Text">Jul 2017 - Nov 2017</Text>
    <Text as='li' variant='ul' mdxType="Text">Managed ~ 12 Linux computers within a CS research laboratory</Text>
    <Text as='li' variant='ul' mdxType="Text">Migrated server that host the research lab website which included reconfiguration of user information schema in LDAP, 
    installation of relevant packages, and staggered data transfer from the old server</Text>
    <Text as='li' variant='ul' mdxType="Text">Installed the open-source OpenVZ virtualization technology on the new server as a replacement for the proprietary 
    VirtualBox application which allowed for the creation of virtual containers that took the functionalities of 3 decommissioned computers at the laboratory</Text>
    <Divider mdxType="Divider" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      